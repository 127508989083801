import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import './styles.css'; // Asegúrate de importar tus estilos personalizados

const CategoryListGeneric = () => {
  const { productId } = useParams();
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    axios.get(`https://www.api.merakimx.mx/gpd/catalog/getCategorys/${productId}`)
      .then(response => {
        setSubcategories(response.data.data);
      })
      .catch(error => {
        console.error("Error fetching subcategories:", error);
      });
  }, [productId]);

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>Categorias 2:</h1>
      <div className="subcategory-list">
        {subcategories.map(subcategory => (
          <div key={subcategory.id} className="subcategory-item-list">
            {subcategory.next && subcategory.next === "product" ? (
              <Link to={`/productList/${subcategory.id}`}>
                <body>
                  <div class="container-card">
                    <img class="img-cart" src={subcategory.thumbnail} style={{height:'360px'}}/>
                    <h4>{subcategory.name.length > 25 ? subcategory.name.slice(0, 25) + "..." : subcategory.name}</h4>
                  </div>
                </body>
              </Link>
            ) : (
              <Link to={`/subcategoryList/${subcategory.id}`}>
                <body>
                  <div class="container-card">
                    <img class="img-cart" src={subcategory.thumbnail} style={{height:'360px'}} />
                    <h4>{subcategory.name.length > 25 ? subcategory.name.slice(0, 25) + "..." : subcategory.name}</h4>
                  </div>
                </body>
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>


  );
};

export default CategoryListGeneric;



