import React, { useContext } from 'react';
import { CartContext } from './CartContext';
import FinishOrderButton from '../finishOrder/finishOrderButton';
import './styles.css'; // Asegúrate de importar tus estilos personalizados

const Cart = () => {
  const { cart, removeFromCart } = useContext(CartContext);
  console.log('cart---->>>>', cart)

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>Carrito de Compras</h1>
      <div className="cart-container-det">
        <div className="product-column">
          {cart.length === 0 ? (
            <p className="empty-cart-message">No hay productos en el carrito.</p>
          ) : (
            cart.map((item, index) => (
              <div key={index} className="product-item-cart">
                <img
                  className="product-thumbnail"
                  src={item.thumbnail}
                  alt="Product Image"
                />
                <div className="product-details">
                  <h4>{item.title}</h4>
                  <h5>Talla: {item.selectedTalla}</h5>
                  {item.discount ? (
                    <p>Precio: ${item.priceDiscount}</p>
                  ) :
                    <p>Precio: ${item.price}</p>
                  }
                </div>
                <button
                  className="remove-button"
                  onClick={() => removeFromCart(index)}
                >
                  Eliminar
                </button>
              </div>
            ))
          )}
        </div>
        {cart.length > 0 && (
          <div className="button-column">
            <div className="order-summary">
              <h2>Orden</h2>
              <hr />
              <div className="order-info">
                <div className="order-info-item">
                  <p>No. Productos</p>
                  <p>{cart.length} productos</p>
                </div>
                <div className="order-info-item">
                  <h6>Total:</h6>
                  <h6>
                    ${cart.reduce((total, item) =>
                      total + (item.discount ? item.priceDiscount : item.price), 0
                    ).toFixed(2)}
                  </h6>
                </div>
              </div>
              <FinishOrderButton productCount={cart.length} />
              <div className="order-summary-note">Esto te llevará al WhatsApp de MERAKI para que puedas enviarles tu pedido o solucionar tus dudas.</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
