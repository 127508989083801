import React, { useEffect, useState, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './styles.css'; // Asegúrate de importar tus estilos personalizados
import { CartContext } from '../car/CartContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TallaModal from '../modal-detail/TallaModal'; // Importa el componente de la modal

const ProductListPrincipal = () => {
  const { productId } = useParams();
  const [subcategories, setSubcategories] = useState([]);
  const { addToCart } = useContext(CartContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedTalla, setSelectedTalla] = useState(null);

  useEffect(() => {
    axios.get(`https://www.api.merakimx.mx/gpd/ad/getAdsInventory/X`)
      .then(response => {
        setSubcategories(response.data.data);
      })
      .catch(error => {
        console.error("Error fetching subcategories:", error);
      });
  }, [productId]);

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Cleanup function to reset the overflow style when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [modalIsOpen]);

  const openModal = (product) => {
    console.log('----entre-------');
    setSelectedProduct(product);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedProduct(null);
    setSelectedTalla(null);
  };

  const handleConfirmAddToCart = (product, talla) => {
    if (talla) {
      addToCart({ ...product, selectedTalla: talla });
      toast.success('Producto agregado al carrito!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setModalIsOpen(false);
      setSelectedProduct(null);
      setSelectedTalla(null);
    } else {
      alert('Selecciona una talla antes de agregar al carrito.');
    }
  };

  const handleAddToCart = (e, product) => {
    e.preventDefault();
    // Verificar si el producto tiene tallas y no es unitalla
    if (product.talla && product.talla.length > 0 && !product.talla.some(talla => talla.name.toLowerCase() === 'unitalla')) {
      setSelectedProduct(product);
      setModalIsOpen(true);
    } else {
      // Agregar directamente al carrito si no tiene tallas o es unitalla
      addToCart(product);
      toast.success('Producto agregado al carrito!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center', color: '#8F695D' }}>Lista de productos:</h1>
      <div className="subcategory-list" >
        {subcategories.map(subcategory => (
          <div key={subcategory._id} className="subcategory-item-list">
            <Link to={`/detail/${subcategory._id}`}>

              <div className="container-card-listProduct" style={{background:'#F7DACA'}}>
                <img className="img-cart" src={subcategory.thumbnail} alt={subcategory.title} style={{ height: '360px' }} />
                <h4 style={{color:'#8F695D'}}>{subcategory.title.length > 25 ? subcategory.title.slice(0, 25) + "..." : subcategory.title}</h4>
                <div className="properties" style={{color:'#8F695D'}}>
                  <div className="precio"  style={{ textAlign: "center" }}>
                    <div>
                      {subcategory.talla && subcategory.talla.length > 0 ? (
                        <div className="talla">
                          <div>{subcategory.talla.map(talla => talla.name).join(', ')}</div>
                        </div>
                      ) : (
                        <div className="talla">Unitalla</div>
                      )}

                      {subcategory.delivery ? (
                        <div className="delivery" style={{color:'#8F695D'}}>Entrega: En stock</div>
                      ) : (
                        <div className="delivery" style={{color:'#8F695D'}}>Entrega: Por Pedido</div>
                      )}

                      {subcategory.discount && (
                        <div className="discount-porperties" style={{color:'#8F695D'}}>
                          <div className="discount">-{subcategory.discountPorcent}% Promocion</div>
                          <p className="priceBeforeTxt">Precio anterior</p>
                          <p className="priceBefore">${subcategory.price}</p>
                        </div>
                      )}
                    </div>
                    {!subcategory.discount && <h4 style={{color:'#8F695D'}}>Precio</h4>}
                    <span className="price-inr" >
                      {subcategory.discount ? (
                        <span className="amount" style={{color:'#8F695D'}}>$ {subcategory.priceDiscount}</span>
                      ) : (
                        <span className="amount" style={{color:'#8F695D'}}>$ {subcategory.price}</span>
                      )}
                    </span>
                  </div>
                </div>
                <div className="add-to-cart-container">
                  <button
                    className="ip-add-cart-inr"
                    onClick={(e) => {
                      e.preventDefault();

                      if (subcategory.talla && subcategory.talla.length > 0 && !subcategory.talla.some(talla => talla.name.toLowerCase() === 'unitalla')) {
                        openModal(subcategory);
                      } else {
                        handleAddToCart(e, subcategory);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faWhatsapp} style={{ color: 'white', marginRight: '10px' }} />
                    Agregar al carrito
                  </button>
                </div>
              </div>
            </Link>

          </div>
        ))}
      </div>
      <TallaModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        selectedProduct={selectedProduct}
        selectedTalla={selectedTalla}
        setSelectedTalla={setSelectedTalla}
        handleConfirmAddToCart={handleConfirmAddToCart}
      />
      <ToastContainer />
    </div>
  );
};

export default ProductListPrincipal;
