// App.js
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home/home';
import MenuBar from './components/menu/menuBar';
import CategoryListGeneric from './components/sub-category/categoryListGeneric';
import CategoryList from './components/sub-category-leve2/categoryList';
import ProductList from './components/listProducts/products';
import BannerComponent from './components/banners/banner';
import Detail from './components/detailProduct/detail';
import ProductListL3 from './components/sub-category-leve3/productListL3';
import CartData from './components/car/cartData';
import FloatingButton from './components/floatingBtn/floatingButton';
import { CartContext } from './components/car/CartContext'; // Importa el contexto del carrito
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';
import backgroundImage from './resources/backgroundImage/backgroundImage.png'; // Ruta a tu imagen de fondo

Modal.setAppElement('#root');

function App() {
  const { cart } = useContext(CartContext); // Obtén el carrito desde el contexto
  return (
<div style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    }}>      <Helmet>
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '440771828757645');
            fbq('track', 'PageView');
          `}
        </script>
        <noscript>{`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=440771828757645&ev=PageView&noscript=1" />`}</noscript>
      </Helmet>
      <MenuBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/categoryList/:productId" element={<CategoryListGeneric />} />
        <Route path="/subcategoryList/:productId" element={<CategoryList />} />
        <Route path="/products/:productId" element={<ProductList />} />
        <Route path="/detail/:productId" element={<Detail />} />
        <Route path="/productList/:productId" element={<ProductListL3 />} />
        <Route path="/cart" element={<CartData />} />
      </Routes>
      <FloatingButton isVisible={cart.length > 0} />

    </div>
  );
}

export default App;


