// TwoColumnsComponent.js
import React from 'react';
import './styles.css'; // Asegúrate de importar tus estilos personalizados
import BannerComponent from '../../components/banners/banner';

const TwoColumnsComponent = () => {
  return (
    <div className="two-columns-container">
      <div className="column">
        <BannerComponent
          text="Protege Nuestro Hogar 2"
          imageSrc="https://www.api.merakimx.mx/gpd/images/bucket/?bukcket=uploads/2024/7/1/1/_2024_7_d6c29ac00623b_a.png"
          showBanner={false}
          txtH3="Descubre nuestras ofertas"
          txtH5="30% de descuento"
          txtBtn="Comprar"
          promotion={1}
          redirectId='R_1_1_1'

        />
      </div>
      <div className="column">
        <BannerComponent
           text="Protege Nuestro Hogar 2"
           imageSrc="https://www.api.merakimx.mx/gpd/images/bucket/?bukcket=uploads/2024/7/1/1/_2024_7_72ca5c9f937ab_a.png"
           showBanner={false}
           txtH3="Hola"
           txtH5="mundo"
           txtBtn="1"
           promotion={2}
           redirectId='R_1_1_12'
        />
      </div>
    </div>
  );
};

export default TwoColumnsComponent;
