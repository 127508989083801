import React, { useState, useEffect } from 'react';
import "./stylesEmail.css"; // Asegúrate de importar tus estilos personalizados

const EmailForm = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (submitted) {
      // Oculta la notificación después de 5 segundos
      const timer = setTimeout(() => {
        setSubmitted(false);
      }, 5000);

      // Limpia el temporizador si el componente se desmonta antes de que el temporizador se ejecute
      return () => clearTimeout(timer);
    }
  }, [submitted]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('https://www.api.merakimx.mx/gpd/ad/message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          mensaje: 'nuevo subscriptor',
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setSubmitted(true); // Cambia el estado a 'submitted' solo si la respuesta es exitosa
      setError('');
      setEmail(''); // Limpia el campo de entrada
    } catch (err) {
      setError('Hubo un problema al enviar el correo.');
      console.error('Error:', err);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%', color: '#8F695D' }}>
      <input
        type="email"
        name="email"
        placeholder="Email Address*"
        required
        pattern="^.+@.+\.[a-zA-Z]{2,63}$"
        maxLength="250"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className='email-sty'
      />
      <button type="submit" className='submit-sty'>Enviar</button>
      {submitted && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '15px',
          color: 'white',
          backgroundColor: '#C6AEA2', // Color de fondo verde
          border: '1px solid #8F695D', // Contorno verde más oscuro
          padding: '10px',
          borderRadius: '5px',
          textAlign: 'center',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
          zIndex: 1000 // Asegura que el cuadro esté por encima de otros elementos
        }}>
          Gracias por subscribirte
        </div>
      )}
      {error && (
        <div style={{ marginTop: '10px', fontSize: '15px', color: 'red' }}>
          {error}
        </div>
      )}
    </form>
  );
};

export default EmailForm;
