import React, { Component } from "react";
import Slider from "react-slick";
import axios from "axios";
import "./stylesCusto.css"; // Asegúrate de importar tus estilos personalizados
import { Link } from "react-router-dom"; // Importa Link de react-router-dom
import EmailForm from '../email/emailForm'; // Ajusta la ruta según sea necesario

class CustomArrows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [], // Inicializa products como un arreglo vacío
    };
  }

  componentDidMount() {
    axios
      .get("https://www.api.merakimx.mx/gpd/catalog/getCategorys/R")
      .then((response) => {
        // Almacena los datos del servicio en el estado
        this.setState({ products: response.data.data });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  render() {
    const { products } = this.state;

    // Verifica si products no es un arreglo
    if (!Array.isArray(products)) {
      return <div>No hay productos disponibles</div>;
    }

    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024, // Se ajustará en pantallas de 1024px o menos
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 768, // Se ajustará en pantallas de 768px o menos
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 480, // Se ajustará en pantallas de 480px o menos
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
      ],
    };

    return (
      <>
        <div className="container-sty">
          <div className="column1">
            {/* Contenido de la primera columna */}
            <span className="txt-off">Obten 20% en tu primera compra</span>
          </div>
          <div className="column2">
            <EmailForm />
          </div>
       </div>
        <div className="slider-container">
          <div className="titleCategory">
            <p style={{ color: "#8F695D" }}>CATEGORIAS</p>
          </div>
          <Slider {...settings}>
            {products.map((product) => (
              <div key={product.id} className="col-md-4 mb-4">
                <div className="card h-100 rounded-card">
                  <Link to={`/categoryList/${product.id}`}>
                    <div className="circle-container">
                      <img
                        className="card-img-top rounded-circle"
                        src={product.thumbnail}
                        alt="Product Image"
                        style={{
                          height: "230px",
                          width: "230px",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                    <div className="card-body d-flex flex-column">
                      <h5
                        className="card-title text-center"
                        style={{ color: "#8F695D" }}
                      >
                        {product.name.length > 20
                          ? product.name.slice(0, 20) + "..."
                          : product.name}
                      </h5>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </>
    );
  }
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "green" }}
      onClick={onClick}
    />
  );
}

export default CustomArrows;
