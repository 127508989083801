import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './TallaModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

Modal.setAppElement('#root');

const TallaModal = ({ isOpen, onRequestClose, selectedProduct, selectedTalla, setSelectedTalla, handleConfirmAddToCart }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isOpen) {
            setIsLoading(true);
            setTimeout(() => setIsLoading(false), 1000);
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="Modal"
            overlayClassName="Overlay"
        >
            {isLoading ? (
                <div className="loader">Cargando...</div>
            ) : (
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>Selecciona una talla para {selectedProduct?.title}</h2>
                        <button className="close-button" onClick={onRequestClose}>×</button>
                    </div>
                    <div className="modal-body">
                        <div className="modal-image-container">
                            <img src={selectedProduct?.thumbnail} alt={selectedProduct?.title} className="modal-image" />
                        </div>
                        <div className="modal-options-container">
                            <ul className="talla-list">
                                {selectedProduct?.talla.map(talla => (
                                    <label key={talla.name}>
                                        <input
                                            type="radio"
                                            value={talla.name}
                                            checked={selectedTalla === talla.name}
                                            onChange={(e) => setSelectedTalla(e.target.value)}
                                        />
                                        {talla.name}
                                    </label>
                                ))}
                            </ul>
                            <div className="modal-buttons">
                                <button className="ip-add-cart-inr"
                                    onClick={() => handleConfirmAddToCart(selectedProduct, selectedTalla)}>
                                    <FontAwesomeIcon icon={faWhatsapp} style={{ color: 'white', marginRight: '10px' }} />
                                    Agregar al carrito
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default TallaModal;
