export const getProductDetail = async (productId) => {
    const response = await fetch(`https://www.api.merakimx.mx/gpd/ad/add/${productId}`);
    if (!response.ok) {
      throw new Error('Error al obtener el detalle del producto');
    }
    const data = await response.json();
    return data;
  };



